import select2 from "select2";
import flatpickr from "flatpickr";
import { it } from "flatpickr/dist/l10n/it.js";

document.addEventListener("turbolinks:before-cache", function () {
  $(".search-select, .country-select").select2("destroy");
});

document.addEventListener("turbolinks:load", function () {
  $(".search-select").select2();

  var country = $(".country-select");
  if (country) {
    country.select2({
      templateResult: formatCountry,
      data: isoCountries,
    });
    country.val(country.data("value")).trigger("change");
  }

  flatpickr(".datepicker", {
    altInput: true,
    altFormat: "d/m/Y",
    dateFormat: "Y-m-d",
    locale: "it",
  });

  $("form .submit-on-change").on("change", function (event) {
    $(this).closest("form").submit();
  });

  $("form .submit-on-enter").keydown(function (event) {
    if (event.keyCode == 13) {
      $(this).closest("form").submit();
    }
  });

  $(".format-country-id").each(function () {
    var countryId = $(this).html().trim();
    if (countryId && countryId != "") {
      $(this).html(formatCountry(isoCountries[countryIndex(countryId)]));
      $(this).removeClass("format-country-id");
    }
  });

  $(".format-country-name").each(function () {
    var countryId = $(this).html().trim();
    if (countryId && countryId != "") {
      $(this).html(formatCountryName(isoCountries[countryIndex(countryId)]));
      $(this).removeClass("format-country-name");
    }
  });

  $(".format-country-flag").each(function () {
    var countryId = $(this).html().trim();
    var height = $(this).data("height");
    if (countryId && countryId != "") {
      $(this).html(
        formatCountryFlag(isoCountries[countryIndex(countryId)], height)
      );
      $(this).removeClass("format-country-flag");
    }
  });

  saveTabSelection("#view-tab-select", "activeViewTab");
});

var isoCountries = [
  { id: "AF", text: "Afghanistan" },
  { id: "AL", text: "Albania" },
  { id: "DZ", text: "Algeria" },
  { id: "AS", text: "American Samoa" },
  { id: "AD", text: "Andorra" },
  { id: "AO", text: "Angola" },
  { id: "AI", text: "Anguilla" },
  { id: "AQ", text: "Antarctica" },
  { id: "AG", text: "Antigua and Barbuda" },
  { id: "AR", text: "Argentina" },
  { id: "AM", text: "Armenia" },
  { id: "AW", text: "Aruba" },
  { id: "AU", text: "Australia" },
  { id: "AT", text: "Austria" },
  { id: "AZ", text: "Azerbaijan" },
  { id: "BS", text: "Bahamas" },
  { id: "BH", text: "Bahrain" },
  { id: "BD", text: "Bangladesh" },
  { id: "BB", text: "Barbados" },
  { id: "BY", text: "Belarus" },
  { id: "BE", text: "Belgium" },
  { id: "BZ", text: "Belize" },
  { id: "BJ", text: "Benin" },
  { id: "BM", text: "Bermuda" },
  { id: "BT", text: "Bhutan" },
  { id: "BO", text: "Bolivia" },
  { id: "BA", text: "Bosnia and Herzegovina" },
  { id: "BW", text: "Botswana" },
  { id: "BV", text: "Bouvet Island" },
  { id: "BR", text: "Brazil" },
  { id: "IO", text: "British Indian Ocean Territory" },
  { id: "BN", text: "Brunei Darussalam" },
  { id: "BG", text: "Bulgaria" },
  { id: "BF", text: "Burkina Faso" },
  { id: "BI", text: "Burundi" },
  { id: "KH", text: "Cambodia" },
  { id: "CM", text: "Cameroon" },
  { id: "CA", text: "Canada" },
  { id: "CV", text: "Cape Verde" },
  { id: "KY", text: "Cayman Islands" },
  { id: "CF", text: "Central African Republic" },
  { id: "TD", text: "Chad" },
  { id: "CL", text: "Chile" },
  { id: "CN", text: "China" },
  { id: "CX", text: "Christmas Island" },
  { id: "CC", text: "Cocos (Keeling) Islands" },
  { id: "CO", text: "Colombia" },
  { id: "KM", text: "Comoros" },
  { id: "CG", text: "Congo" },
  { id: "CD", text: "Congo, the Democratic Republic of the" },
  { id: "CK", text: "Cook Islands" },
  { id: "CR", text: "Costa Rica" },
  { id: "CI", text: "Cote D'Ivoire" },
  { id: "HR", text: "Croatia" },
  { id: "CU", text: "Cuba" },
  { id: "CY", text: "Cyprus" },
  { id: "CZ", text: "Czech Republic" },
  { id: "DK", text: "Denmark" },
  { id: "DJ", text: "Djibouti" },
  { id: "DM", text: "Dominica" },
  { id: "DO", text: "Dominican Republic" },
  { id: "EC", text: "Ecuador" },
  { id: "EG", text: "Egypt" },
  { id: "SV", text: "El Salvador" },
  { id: "GQ", text: "Equatorial Guinea" },
  { id: "ER", text: "Eritrea" },
  { id: "EE", text: "Estonia" },
  { id: "ET", text: "Ethiopia" },
  { id: "FK", text: "Falkland Islands (Malvinas)" },
  { id: "FO", text: "Faroe Islands" },
  { id: "FJ", text: "Fiji" },
  { id: "FI", text: "Finland" },
  { id: "FR", text: "France" },
  { id: "GF", text: "French Guiana" },
  { id: "PF", text: "French Polynesia" },
  { id: "TF", text: "French Southern Territories" },
  { id: "GA", text: "Gabon" },
  { id: "GM", text: "Gambia" },
  { id: "GE", text: "Georgia" },
  { id: "DE", text: "Germany" },
  { id: "GH", text: "Ghana" },
  { id: "GI", text: "Gibraltar" },
  { id: "GR", text: "Greece" },
  { id: "GL", text: "Greenland" },
  { id: "GD", text: "Grenada" },
  { id: "GP", text: "Guadeloupe" },
  { id: "GU", text: "Guam" },
  { id: "GT", text: "Guatemala" },
  { id: "GN", text: "Guinea" },
  { id: "GW", text: "Guinea-Bissau" },
  { id: "GY", text: "Guyana" },
  { id: "HT", text: "Haiti" },
  { id: "HM", text: "Heard Island and Mcdonald Islands" },
  { id: "VA", text: "Holy See (Vatican City State)" },
  { id: "HN", text: "Honduras" },
  { id: "HK", text: "Hong Kong" },
  { id: "HU", text: "Hungary" },
  { id: "IS", text: "Iceland" },
  { id: "IN", text: "India" },
  { id: "ID", text: "Indonesia" },
  { id: "IR", text: "Iran, Islamic Republic of" },
  { id: "IQ", text: "Iraq" },
  { id: "IE", text: "Ireland" },
  { id: "IL", text: "Israel" },
  { id: "IT", text: "Italy" },
  { id: "JM", text: "Jamaica" },
  { id: "JP", text: "Japan" },
  { id: "JO", text: "Jordan" },
  { id: "KZ", text: "Kazakhstan" },
  { id: "KE", text: "Kenya" },
  { id: "KI", text: "Kiribati" },
  { id: "KP", text: "North Korea" },
  { id: "KR", text: "South Korea" },
  { id: "KW", text: "Kuwait" },
  { id: "KG", text: "Kyrgyzstan" },
  { id: "LA", text: "Lao People's Democratic Republic" },
  { id: "LV", text: "Latvia" },
  { id: "LB", text: "Lebanon" },
  { id: "LS", text: "Lesotho" },
  { id: "LR", text: "Liberia" },
  { id: "LY", text: "Libya" },
  { id: "LI", text: "Liechtenstein" },
  { id: "LT", text: "Lithuania" },
  { id: "LU", text: "Luxembourg" },
  { id: "MO", text: "Macao" },
  { id: "MG", text: "Madagascar" },
  { id: "MW", text: "Malawi" },
  { id: "MY", text: "Malaysia" },
  { id: "MV", text: "Maldives" },
  { id: "ML", text: "Mali" },
  { id: "MT", text: "Malta" },
  { id: "MH", text: "Marshall Islands" },
  { id: "MQ", text: "Martinique" },
  { id: "MR", text: "Mauritania" },
  { id: "MU", text: "Mauritius" },
  { id: "YT", text: "Mayotte" },
  { id: "MX", text: "Mexico" },
  { id: "FM", text: "Micronesia, Federated States of" },
  { id: "MD", text: "Moldova, Republic of" },
  { id: "MC", text: "Monaco" },
  { id: "MN", text: "Mongolia" },
  { id: "MS", text: "Montserrat" },
  { id: "MA", text: "Morocco" },
  { id: "MZ", text: "Mozambique" },
  { id: "MM", text: "Myanmar" },
  { id: "NA", text: "Namibia" },
  { id: "NR", text: "Nauru" },
  { id: "NP", text: "Nepal" },
  { id: "NL", text: "Netherlands" },
  { id: "NC", text: "New Caledonia" },
  { id: "NZ", text: "New Zealand" },
  { id: "NI", text: "Nicaragua" },
  { id: "NE", text: "Niger" },
  { id: "NG", text: "Nigeria" },
  { id: "NU", text: "Niue" },
  { id: "NF", text: "Norfolk Island" },
  { id: "MK", text: "North Macedonia, Republic of" },
  { id: "MP", text: "Northern Mariana Islands" },
  { id: "NO", text: "Norway" },
  { id: "OM", text: "Oman" },
  { id: "PK", text: "Pakistan" },
  { id: "PW", text: "Palau" },
  { id: "PS", text: "Palestinian Territory, Occupied" },
  { id: "PA", text: "Panama" },
  { id: "PG", text: "Papua New Guinea" },
  { id: "PY", text: "Paraguay" },
  { id: "PE", text: "Peru" },
  { id: "PH", text: "Philippines" },
  { id: "PN", text: "Pitcairn" },
  { id: "PL", text: "Poland" },
  { id: "PT", text: "Portugal" },
  { id: "PR", text: "Puerto Rico" },
  { id: "QA", text: "Qatar" },
  { id: "RE", text: "Reunion" },
  { id: "RO", text: "Romania" },
  { id: "RU", text: "Russian Federation" },
  { id: "RW", text: "Rwanda" },
  { id: "SH", text: "Saint Helena" },
  { id: "KN", text: "Saint Kitts and Nevis" },
  { id: "LC", text: "Saint Lucia" },
  { id: "PM", text: "Saint Pierre and Miquelon" },
  { id: "VC", text: "Saint Vincent and the Grenadines" },
  { id: "WS", text: "Samoa" },
  { id: "SM", text: "San Marino" },
  { id: "ST", text: "Sao Tome and Principe" },
  { id: "SA", text: "Saudi Arabia" },
  { id: "SN", text: "Senegal" },
  { id: "SC", text: "Seychelles" },
  { id: "SL", text: "Sierra Leone" },
  { id: "SG", text: "Singapore" },
  { id: "SK", text: "Slovakia" },
  { id: "SI", text: "Slovenia" },
  { id: "SB", text: "Solomon Islands" },
  { id: "SO", text: "Somalia" },
  { id: "XS", text: "Somaliland" },
  { id: "ZA", text: "South Africa" },
  { id: "GS", text: "South Georgia and the South Sandwich Islands" },
  { id: "ES", text: "Spain" },
  { id: "LK", text: "Sri Lanka" },
  { id: "SD", text: "Sudan" },
  { id: "SR", text: "Suriname" },
  { id: "SJ", text: "Svalbard and Jan Mayen" },
  { id: "SZ", text: "Eswatini" },
  { id: "SE", text: "Sweden" },
  { id: "CH", text: "Switzerland" },
  { id: "SY", text: "Syrian Arab Republic" },
  { id: "TW", text: "Taiwan" },
  { id: "TJ", text: "Tajikistan" },
  { id: "TZ", text: "Tanzania, United Republic of" },
  { id: "TH", text: "Thailand" },
  { id: "TL", text: "Timor-Leste" },
  { id: "TG", text: "Togo" },
  { id: "TK", text: "Tokelau" },
  { id: "TO", text: "Tonga" },
  { id: "TT", text: "Trinidad and Tobago" },
  { id: "TN", text: "Tunisia" },
  { id: "TR", text: "Turkiye" },
  { id: "TM", text: "Turkmenistan" },
  { id: "TC", text: "Turks and Caicos Islands" },
  { id: "TV", text: "Tuvalu" },
  { id: "UG", text: "Uganda" },
  { id: "UA", text: "Ukraine" },
  { id: "AE", text: "United Arab Emirates" },
  { id: "GB", text: "United Kingdom" },
  { id: "US", text: "United States of America" },
  { id: "UM", text: "United States Minor Outlying Islands" },
  { id: "UY", text: "Uruguay" },
  { id: "UZ", text: "Uzbekistan" },
  { id: "VU", text: "Vanuatu" },
  { id: "VE", text: "Venezuela" },
  { id: "VN", text: "Vietnam" },
  { id: "VG", text: "Virgin Islands, British" },
  { id: "VI", text: "Virgin Islands, U.S." },
  { id: "WF", text: "Wallis and Futuna" },
  { id: "EH", text: "Western Sahara" },
  { id: "YE", text: "Yemen" },
  { id: "ZM", text: "Zambia" },
  { id: "ZW", text: "Zimbabwe" },
  { id: "AX", text: "Åland Islands" },
  { id: "BQ", text: "Bonaire, Sint Eustatius and Saba" },
  { id: "CW", text: "Curaçao" },
  { id: "GG", text: "Guernsey" },
  { id: "IM", text: "Isle of Man" },
  { id: "JE", text: "Jersey" },
  { id: "ME", text: "Montenegro" },
  { id: "BL", text: "Saint Barthélemy" },
  { id: "MF", text: "Saint Martin (French part)" },
  { id: "RS", text: "Serbia" },
  { id: "SX", text: "Sint Maarten (Dutch part)" },
  { id: "SS", text: "South Sudan" },
  { id: "XK", text: "Kosovo" },
];

function formatCountry(country) {
  if (!country.id) {
    return country.text;
  }
  var $country = $(
    '<span class="flag-icon flag-icon-' +
      country.id.toLowerCase() +
      ' flag-icon-squared"></span>&nbsp;&nbsp;' +
      '<span class="flag-text">' +
      country.text +
      "</span>"
  );
  return $country;
}

function formatCountryName(country) {
  return country.text;
}

function formatCountryFlag(country, height) {
  if (!country.id) {
    return "";
  }
  var style = "";
  if (height) {
    style = "height: " + height + "px; width: 100%;";
  }
  var $country = $(
    '<span class="flag-icon flag-icon-' +
      country.id.toLowerCase() +
      '"' +
      ' style="' +
      style +
      '"' +
      "></span>"
  );
  return $country;
}

function countryIndex(countryId) {
  // var index = isoCountries.findIndex(obj => obj.id==countryId);
  var index = isoCountries.findIndex(function (x) {
    return x.id == countryId;
  });
  return index;
}

function saveTabSelection(parent, property) {
  $(parent + ' button[data-toggle="tab"]').on("show.bs.tab", function (e) {
    localStorage.setItem(property, $(e.target).attr("data-target"));
  });
  $(parent + ' a[data-toggle="tab"]').on("show.bs.tab", function (e) {
    localStorage.setItem(property, $(e.target).attr("data-target"));
  });

  var activeViewTab = localStorage.getItem(property);
  if (activeViewTab) {
    $(parent + ' button[data-target="' + activeViewTab + '"]').tab("show");
    $(parent + ' a[data-target="' + activeViewTab + '"]').tab("show");
  }
}
